<template>
    <div>
        <restaurants-component/>
        <footer-component/>
    </div>
</template>

<script>
    import RestaurantsComponent from "@/components/restaurants-leisures/RestaurantsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Restaurants",
        title: "Restaurantes | Turismo BC",
        components: {
            RestaurantsComponent,
            FooterComponent
        },
    }
</script>

<style scoped>

</style>